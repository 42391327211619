export const Health_Status_Data = [
    {
      current_status: 1,
      service_name: "Anywhere Backend",
      description: "Backend service responsible for handling server-side logic.",
      last_failed_at: "2024-10-01T10:00:00Z",
      last_25_pings: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      availability: "100%",
    },
   
  ];
  