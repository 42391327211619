import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from 'moment';

interface IModal {
  [name: string]: boolean;
}

interface IDateRange {
  fromDate: string | null; toDate: string | null;
}

interface UiSlice {
  modal: IModal;
  isDeleteConfirmed: boolean;
  selectedDateRange: IDateRange;
}
export const uiInitialState: UiSlice = {
  modal: {} as IModal,
  isDeleteConfirmed: false,
  selectedDateRange: { 
    fromDate: moment().startOf('month').set({hour: 0, minute: 0, second: 0})?.toString(), 
    toDate: moment().set({hour: 23, minute: 59, second: 59})?.toString() 
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: uiInitialState,
  reducers: {
    openModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = true;
    },
    closeModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = false;
    },
    updateDeleteConfirmation: (state: UiSlice, { payload }: PayloadAction<boolean>) => {
      state.isDeleteConfirmed = payload;
    },
    setSelectedDateRange: (state: UiSlice, { payload }: PayloadAction<IDateRange>) => {
      state.selectedDateRange = payload;
    },
  },
});

export const { openModal, closeModal, updateDeleteConfirmation, setSelectedDateRange } = uiSlice.actions;

export default uiSlice.reducer;
