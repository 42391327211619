import React, { useEffect, useState } from "react";
import { DatePicker, Loading } from "../../components";
import moment, { Moment } from "moment";
import DonutChart from "./graphs/DonutChart";
import { ProcessingTrend } from "./graphs/ProcessingTrend";
import ApiCallStatistics from "./graphs/APICallStatistics";
import { APICallTrend } from "./graphs/APICallTrend";
import "./graphs/graphs.scss";
import { RootState, setSelectedDateRange } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { ChartStatsData } from "../../interfaces/document";
// import { RangeValue } from "rc-picker/lib";
import { mockDocStats, mockTokenData } from "./constants";
import { documentService } from "../../api";

interface DocStatsType {
  totalDocsWithData: number;
  totalFields: number;
  totalExtractedFields: number;
  totalMissingFields: number;
  totalSrfPageFound: number;
  totalSuccess: number;
  totalFailed: number;
  totalInProgress: number;
  dateWiseExtractionData: { date: string; processed: number }[];
}

type TokenData = {
  date: string;
  token_count: {
    service_name: string;
    moduleName: string;
    token_count: number;
  }[];
}[];


interface ProcessingTrendProps {
  data: { date: string; processed: number }[];
}

export const SummaryDataAndGraphs = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const [docStats, setDocStats] = useState<ChartStatsData[]>([]);
  const [chartData, setChartData] = useState({
    READY: 0,
    FAILED: 0,
    PROCESSING: 0,
  });
  const [tokenCount, setTokenCount] = useState<any>(null);
  const [extractionDataCount, setExtractionDataCount] =
    useState<ProcessingTrendProps | null>(null);
  const [isLoadingChartStats, setIsLoadingChartStats] = useState(false);
  const [isLoadingApiStats, setIsLoadingApiStats] = useState(false);
  const defaultFromDate = moment().startOf('month');
  const defaultToDate = moment();


  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );

  const onRangeChange = (dates: any, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: null,
          toDate: null,
        })
      );
    }
  };

  // Mock API Data fetching for token and chart stats
  const fetchApiStats = async () => {
    setIsLoadingApiStats(true);

    setTokenCount(mockTokenData);
    setIsLoadingApiStats(false);
  };

  const fetchChartStats = async () => {
    setIsLoadingChartStats(true);
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Format dates
      const fromDate = moment(selectedFromDate).format('YYYY-MM-DD HH:mm:ss');
      const toDate = moment(selectedToDate).format('YYYY-MM-DD HH:mm:ss');;
    
      const { data } = await documentService.getDocumentStats(fromDate, toDate, timezone);
      setDocStats(data);

      const mockChartStats = data.reduce((acc:any, item:any) => {
        const status = item.status ? item.status.toUpperCase() : "FAILED";
            acc[status] = (acc[status] || 0) + item.processed;
        return acc;
    }, {});
    
    setChartData(mockChartStats);
    
    } catch (error) {}
    setIsLoadingChartStats(false);
  };

  useEffect(() => {
    fetchApiStats();
    fetchChartStats();
  }, [selectedFromDate, selectedToDate]);

  const renderDatePicker = () => (
    <div className="service-datePicker">
      <p className="service-datePicker-title">Select Date Range:</p>
      <RangePicker
        showTime
        defaultValue={[
          selectedFromDate ? moment(selectedFromDate) : null,
          selectedToDate ? moment(selectedToDate) : null,
        ]}
        onChange={onRangeChange}
        className="range-picker"
        allowClear={false}
        disabledDate={(current) =>
          current && current.valueOf() > moment().endOf("day").valueOf()
        }
      />
    </div>
  );

  const renderDonutChart = () => (
    <div className={`summary-container other-copilots`}>
      <div className="donut-container">
        <h3 className="summary-title">Documents Processed</h3>
        <p>Total documents processed with status success, failed</p>
        <DonutChart
          donutData={[
            { type: "Success", value: chartData.READY || 0},
            { type: "Failed", value: chartData.FAILED || 0},
            { type: "Processing", value: chartData.PROCESSING || 0},
          ]}
        />
      </div>
      
    </div>
  );

  const renderApiCallStatistics = () => (
    <div className={`summary-container other-copilots`}>
      <div className="processing-trend">
        <ProcessingTrend
          data={docStats}
          fromDate={moment(selectedFromDate)}
          toDate={moment(selectedToDate)}
        />
      </div>
    </div>
  );

  const renderLoading = (message: string) => (
    <div style={{ height: "200px", width: "50%" }}>
      <Loading tip={message} />
    </div>
  );

  return (
    <div className="wrapper">
      <div className="service-status">
        <h4 className="service-status-title">Insights</h4>
        <div className="service-horizontal"></div>
        {renderDatePicker()}
      </div>

      <div
        style={{ display: "flex", gap: "20px", justifyContent: "space-evenly" }}
      >
        {isLoadingChartStats
          ? renderLoading("Loading documents stats...")
          : docStats && (
              <div className={`summary-container row-layout`}>
                {renderDonutChart()}
                {renderApiCallStatistics()}
              </div>
            )}
      </div>
    </div>
  );
};
