import { IRepsonseWrapper } from ".";
import { SplitDataType } from "../containers/document-container/constants";
import { DocumentData, IDocumentsListRes } from "../interfaces/document";
import { apiClient, withAuthHeaders, qs } from "./apiClient";

const getDocuments = async (reqObj: any): Promise<IDocumentsListRes> => {
  if (reqObj.searchTerm) {
    reqObj.limit = 0;
    reqObj.offset = 0;
  }
    const { data } = await apiClient.get(`/document-analytics`, {
    params: reqObj,
  });

  return data;
};

const getDocumentByUuid = async (
  documentUuid: string
): Promise<DocumentData> => {
  const { data } = await apiClient.get(`/file/document-review/${documentUuid}`);
  return data.data;
};

const getSignedUrlByFilepath = async (path: string) => {
  const { data } = await apiClient.get("/storage/signedUrl/readByName", {
    params: { path },
  });
  return data.data;
};

const updateStatus = async (
  sectionId: number,
  status: string = "",
  key?: string
) => {
  const { data } = await apiClient.put(
    `/section/feedback-status/${sectionId}`,
    {
      status,
      key,
    }
  );
  return data.data;
};

const getSectionsAndInsights = async (uuid?: string) => {
  const res = await apiClient.get<IRepsonseWrapper<SplitDataType[]>>(
    `/document-analytics/document/${uuid}`
  );
  return res.data;
};

const getDocumentStats = async (fromDate?: string, toDate?: string, timezone?: string) => {
  const params = new URLSearchParams();
  
  if (fromDate) params.append('fromDate', fromDate);
  if (toDate) params.append('toDate', toDate);
  if (timezone) params.append('timezone', timezone);

  const res = await apiClient.get(`/document-analytics/status-report`, { params });
  return res.data;
};

const getAcknowledgementInfo = async(docUUID: string = "1111f7ee-c9cf-42e2-8040-0799044e5784") => {
  const res = await apiClient.get(`/events/${docUUID}`);
  return res.data;
}

export const documentService = {
  getSignedUrlByFilepath,
  getDocuments,
  getDocumentByUuid,
  updateStatus,
  getSectionsAndInsights,
  getDocumentStats,
  getAcknowledgementInfo
};
